import { routers } from "@/config/router.config";
import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index";

export const router = createRouter({
  history: createWebHistory(),
  routes: routers,
});
const allowList = ["login", "home"]; // no redirect allowList
// 前置守卫;
router.beforeEach((to, from, next) => {
  console.log(to, from);
  let Token = localStorage.getItem("Token");
  let Userid = localStorage.getItem("Userid");
  // 如果id 和 token  存在说明是以登录
  if (Token && Userid) {
    let Nickname = localStorage.getItem("Nickname");
    let Name = localStorage.getItem("Name");
    let Logo = localStorage.getItem("Logo");
    store.commit("SetLoginState", "Yes"); //登录状态改为yes
    //防止刷新后页面信息丢失  将页面展示的内容赋值
    store.commit("SteUserInfo", {
      Userid,
      Nickname,
      Name,
      Logo,
    });
    next(); //表示已经登录
  } else {
    store.commit("SetLoginState", "No");
    store.commit("SteUserInfo", {
      Userid: "",
      Nickname: "",
      Name: "",
      Logo: "",
    });
    if (allowList.includes(to.name)) {
      next();
    } else {
      next("/");
    }
  }
});

router.afterEach((to, from) => {
  console.log("进入页面之后调用");
  console.log("要进入的页面" + to);
  console.log("上一个页面" + from);
});
