<template>
  <div class="heads">
    <nav style="z-index: 1000">
      <!-- <div class="company">
         <img :src="url" alt="logo" class="commpanyhead">
         <b style="color:#3e8f83;position: relative;top:5px;font-family:'楷体';">{{msg}}</b>
       </div> -->
      <ul class="list">
        <li class="lis">
          <div class="TabTip" @click="GoHome">首页</div>
        </li>
        <li class="lis">
          <div class="TabTip">开发API</div>
        </li>
        <li class="lis" @click="SignInNow('Register')">
          <div class="TabTip">厂家注册</div>
        </li>
      </ul>
      <div
        class="personalcenter"
        @mouseover="mouseenters(2)"
        @mouseout="mouseenters(1)"
      >
        <div class="UserBox">
          <img
            :src="
              this.$store.state.LoginState == 'No'
                ? 'https://media.lotusdata.com/183134615584750619.png'
                : this.$store.state.UserInfo.Logo
            "
            alt="登录"
          />
          <div class="InfoName">
            {{
              this.$store.state.UserInfo.Name
                ? this.$store.state.UserInfo.Name
                : "暂未登录！"
            }}
          </div>
        </div>
        <div
          class="infopage"
          v-show="loginstatus"
          :style="{
            height: this.$store.state.LoginState == 'Yes' ? '145px' : '75px',
          }"
        >
          <ul class="infopageul" v-show="this.$store.state.LoginState == 'Yes'">
            <li class="infopageli" @click="EquipmentApplication">设备管理</li>
            <li class="infopageli">设备续费</li>
          </ul>
          <div class="buttonbox">
            <div
              v-show="this.$store.state.LoginState == 'No'"
              class="button1"
              @click="SignInNow('Login')"
            >
              登录/注册
            </div>
            <div
              v-show="this.$store.state.LoginState == 'Yes'"
              class="button1"
              @click="LogOut"
            >
              退出登录
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
   
   <script>
export default {
  name: "HeadS",
  data() {
    return {
      loginstatus: false,
      HeadsState: true,
    };
  },
  props: {
    msg: String,
    url: String,
  },
  mounted() {
    let that = this;
    that.$nextTick(() => {});
  },
  methods: {
    // 设备申请
    EquipmentApplication() {
      if (this.$store.state.LoginState == "Yes") {
        this.$router.push("/equipmentapplication");
      } else {
        this.$message.error("请登录后操作！");
        return;
      }
    },
    // 退出登录
    LogOut() {
      localStorage.clear();
      this.$store.commit("SetLoginState", "No");
      this.$store.commit("SteUserInfo", {
        Userid: "",
        Nickname: "",
        Name: "",
        Logo: "",
      });
      this.$message.success("已退出登录");
    },
    // 去首页
    GoHome() {
      this.$router.push("/");
    },
    // 去登录/注册
    SignInNow(type) {
      if (type == "Register") {
        if (this.$store.state.LoginState == "Yes") {
          this.$message.error("您已登录，无法在进行注册！");
          return;
        }
      }
      this.$router.push({ name: "login", query: { Type: type } });
    },
    // 鼠标移到用户信息上的切换
    mouseenters(e) {
      if (e === 1) {
        this.loginstatus = false;
      } else {
        this.loginstatus = true;
      }
    },
  },
};
</script>
   <style lang="less" scoped>
h1 {
  margin: 0px;
}
* {
  margin: 0;
  padding: 0;
}
nav {
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 84px;
  background: rgb(73, 74, 95);
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: center;
}
.company {
  width: 15%;
  /* background: #72727f; */
  line-height: 84px;
  cursor: pointer;
  font-size: 23px;
  width: 320px;
  color: #d3d3d3;
  text-align: center;
}
.company .commpanyhead {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 10px;
}
.list {
  width: 70%;
  height: 84px;
  display: flex;
  justify-content: space-around;
}
.buttonbox {
  width: 100%;
  margin-top: 8px;
  text-align: center;
}
.button1 {
  color: #ffffff;
  border-radius: 10px;
  font-size: 14px;
  padding: 10px 20px;
  background-color: #42b983;
}

.button1:hover {
  background-color: #96c50a;
}
.personalcenter {
  width: 14vw;
  text-align: left;
  height: 56px;
  left: 4vw;
  /* left: 10vw; */
  margin-top: 12px;
  /* border-radius: 50%; */
  /* background: #72727f; */
  position: relative;
  cursor: pointer;
}
.personalcenter img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: #72727f;
  position: relative;
  cursor: pointer;
}
.infopage {
  position: relative;
  top: 0px;
  color: #fff;
  padding: 10px;
  box-sizing: border-box;
  border-top: 1px solid #444;
  border-radius: 0px 0px 10px 10px;
  width: 17vw;
  font-weight: 400;
  right: 0vw;
  background: #494a5f;
}
.infopageli {
  width: 50%;
  min-height: 68px;
  line-height: 68px;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
}
.infopageli:hover {
  background: #444;
  /* color: #42b983; */
  font-weight: 800;
}
.topinfo {
  text-align: left;
  font-size: 15px;
  color: #d3d3d3;
}
.list .lis {
  height: 84px;
  line-height: 84px;
  list-style: none;
  text-decoration: none;
}
.TabTip {
  display: block;
  text-decoration: none;
  height: 75px;
}
.TabTip:link,
.TabTip:visited {
  /* 链接属性，点击前和点击后样式设计一样 */
  color: #d3d3d3;
}
.TabTip:hover {
  /* #鼠标放在a标签上面显示的样式，这里鼠标放上改变字体颜色，并出现下划线 */
  color: white;
  font-size: 25px;
  border-bottom: 2px solid #fff;
  cursor: pointer;
}
h3 {
  margin: 40px 0 0;
}
html {
  margin: 0px;
}
body {
  margin: 0px;
}
.list {
  list-style-type: none;
  padding: 0;
}
.lis {
  display: inline-block;
}
.TabTip,
.InfoName {
  color: #fff;
}
.UserBox {
  width: 100%;
  display: flex;
  align-items: center;
  .InfoName {
    margin-left: 15px;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
   