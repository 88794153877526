export const asyncRouterMap = [
  {
    path: "/",
    name: "home",
    component: () => import("@/view/home/Home.vue"),
  },
  {
    path: "/equipmentapplication",
    name: "equipmentapplication",
    component: () =>
      import("@/view/EquipmentApplication/EquipmentApplication.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/view/login/LogIn.vue"),
  },
];

export const routers = [...asyncRouterMap];
