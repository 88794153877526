import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router/index'
import store from './store'
import Antd from 'ant-design-vue/es';
import 'ant-design-vue/dist/antd.css';
import 'es6-promise/auto'

const app = createApp(App)
app.use(router)
app.use(Antd)
app.use(store)
app.mount('#app')
