<template>
  <div id="app">
    <Heads msg="西安阿科索健康有限公司"></Heads>
    <Contents></Contents>
    <Footer></Footer>
  </div>
</template>

<script>
import Heads from "./components/Heads.vue";
import Contents from "./components/HelloWorld.vue";
import Footer from "./components/Footer.vue";
import { getsystoken } from "@/api/api";
export default {
  name: "App",
  components: {
    Heads,
    Footer,
    Contents,
  },
  mounted() {
    // 获取公共token
    getsystoken().then((res) => {
      if (res.code == "0") {
        localStorage.setItem("SysToken", "JWT " + res.token);
      }
    });
  },
};
</script>

<style>
body {
  padding: 0px;
  margin: 0px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
