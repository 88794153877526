import { createStore } from "vuex";

// 创建一个新的 store 实例
const store = createStore({
  state: {
    //存放数据，
    systoken: "", //公共token
    userid: "", // 用户id
    age: 10,
    LoginState: "No",
    UserInfo: {
      Userid: "",
      Nickname: "",
      Name: "",
      Logo: "",
    },
  },
  getters: {
    // 需要通过计算获取state里的内容获取的数据只能读取不能修改 vue3.2才可实现计算属性
    changeAge(state) {
      return state.age + 4;
    },
  },
  // 定义对state的各种操作 why不能实现在mutation里需要写到action里
  // mtations不能执行异步操作。aq:从云端获取信息-->（等待云端反馈）更新到state异步操作
  // 因此说：对于异步操作需要放到action里，简单的直接赋值操作可以直接放到mutaion里
  mutations: {
    // 修改数据同步
    addAge(state, data) {
      state.age += data;
    },
    // 修改登录状态
    SetLoginState(state, status) {
      state.LoginState = status;
    },
    // 设置用户信息
    SteUserInfo(state, data) {
      state.UserInfo = data;
    },
  },
  //3、 定义对state的各种操作
  // actions无法直接修改state. 需要在mutations里更新
  // mutation不支持异步，所以需要在action里写api到url
  actions: {
    ayAge({ commit }, data) {
      setTimeout(() => {
        commit("addAge", data);
      }, 2000);
    },
  },
  // state中信息过长时
  // 用来将state进行分割
  // 如下，将state树分割出一个user state。
  modules: {},
});

export default store;
