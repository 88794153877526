<template>
  <div class="hello">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  height: 100%;
}
html {
  height: 100%;
}
.hello {
  min-height: calc(100vh);
  margin: 0 auto;
  padding-top: 84px;
  width: 75%;
  text-align: left;
}
</style>
