<template>
    <div class="heads">
     <nav>
      Copyright 2003- 2022陕西省保健学会版权所有陕ICP备18015315号
     </nav>
    </div>
   </template>
   
   <script>
   export default {
     name: 'HeadS',
     props: {
       msg: String,
       url: String
     }
   }
   </script>
   <style scoped>
   h1 {
     margin: 0px;
   }
   * {
       margin: 0;
       padding: 0;
     }
   h3 {
     margin: 40px 0 0;
   }
   html{
     margin: 0px;
   }
   body{
     margin: 0px;
   }
   .heads{
     height: 60px;
     color: #fff;
     background: rgba(73, 74, 95,.95);
     text-align: center;
     line-height: 60px;
   }
   ul {
     list-style-type: none;
     padding: 0;
   }
   li {
     display: inline-block;
   }
   a {
     color: #42b983;
   }
   </style>
   