import axios from "axios";
import { message } from "ant-design-vue";
import { router } from "../router/index";

//1. 创建axios对象
const service = axios.create({
  baseURL: "https://api.lotusdata.com",
  // baseURL: "http://192.168.1.225:8089",//接口不通  模拟实现！
  timeout: 70000,
});
//2. 请求拦截器
service.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("Token");
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//3. 响应拦截器
service.interceptors.response.use(
  (response) => {
    if (response.data.code == "-2") {
      message.error("登录已过期！");
      localStorage.clear();
      router.replace("/");
    }
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
