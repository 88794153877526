import service from "@/request/reques";
import SysReques from "@/request/SysReques";
// /iotcloudmanage/v1 路由加个前缀
const userApi = {
  systoken: "v1/buser/token", //获取公共token
  UploadApi: "v1/file/standardupload", // 上传file
  ManufacturerRegistration:
    "iotcloudmanage/v1/iotdevice/devicefactory/registered", //厂商注册
  VendorLogin: "https://auth.lotusdata.com/v1/login/token", //厂商登录
  VendorLoginInfo: "https://auth.lotusdata.com/v1/validatetoken", //厂商登录后获取信息
  QueryYourOwnDeviceList: "iotcloudmanage/v1/iotdevice/device/list/", //厂商查询自己的设备列表
  AllFunctions:
    "iotcloudmanage/v1/iotdevice/devicefunction/list?start=0&limit=999", //查询全部功能
  RegisterDevice: "iotcloudmanage/v1/iotdevice/device", //注册设备/编辑
  DeleteDevice: "iotcloudmanage/v1/iotdevice/device/", //删除设备
  ListOfEquipmentFunctionRules: "iotcloudmanage/v1/iotdevice/rule/list/", //设备功能规则列表
  AddEditRule: "iotcloudmanage/v1/iotdevice/rule", //设备添加/编辑/删除 规则
};

// 获取公共token
export function getsystoken() {
  return SysReques({
    url: userApi.systoken,
    method: "POST",
    data: {
      username: "system@akso.com",
      password: "123123",
      refreshtoken: "0",
    },
  });
}

// 上传file
export function PostUpload(data) {
  return SysReques({
    url: userApi.UploadApi,
    method: "POST",
    data,
  });
}

// 厂商注册
export function PostManufacturerRegistration(data) {
  return service({
    url: userApi.ManufacturerRegistration,
    method: "POST",
    data,
  });
}

// 厂商登录
export function PostVendorLogin(data) {
  return service({
    url: userApi.VendorLogin,
    method: "POST",
    data,
  });
}

// 厂商登录并获取信息
export function PostVendorLoginInfo(data) {
  return service({
    url: userApi.VendorLoginInfo,
    method: "POST",
    data,
  });
}

// 厂商查询自己的设备列表
export function GetQueryYourOwnDeviceList(ID, Key, Start, Limit) {
  return service({
    url:
      userApi.QueryYourOwnDeviceList +
      ID +
      "?key=" +
      Key +
      "&start=" +
      Start +
      "&limit=" +
      Limit,
    method: "GET",
  });
}

// 厂商查询查询全部功能
export function GetAllFunctions() {
  return service({
    url: userApi.AllFunctions,
    method: "GET",
  });
}

// 厂商注册设备
export function PostRegisterDevice(data) {
  return service({
    url: userApi.RegisterDevice,
    method: "POST",
    data,
  });
}

// 厂商编辑设备
export function PutRegisterDevice(data) {
  return service({
    url: userApi.RegisterDevice,
    method: "PUT",
    data,
  });
}

// 厂商删除设备
export function DeleteDeviceInterface(ID) {
  return service({
    url: userApi.DeleteDevice + ID,
    method: "DELETE",
  });
}

// 厂商查询设备功能规则列表
export function GetListOfEquipmentFunctionRules(ID) {
  return service({
    url: userApi.ListOfEquipmentFunctionRules + ID,
    method: "GET",
  });
}

// 厂商设备添加规则
export function PostAddEditRule(data) {
  return service({
    url: userApi.AddEditRule,
    method: "POST",
    data,
  });
}

// 厂商编辑规则
export function PutAddEditRule(data) {
  return service({
    url: userApi.AddEditRule,
    method: "PUT",
    data,
  });
}

// 厂商删除规则
export function DeleteAddEditRule(ID) {
  return service({
    url: userApi.AddEditRule + "/" + ID,
    method: "DELETE",
  });
}
