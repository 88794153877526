import axios from "axios";
import { getsystoken } from "@/api/api";
import { message } from "ant-design-vue";
//1. 创建axios对象
const service = axios.create({
  baseURL: "https://api.lotusdata.com",
  timeout: 70000,
});
//2. 请求拦截器
service.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("SysToken");
    console.log(token);
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//3. 响应拦截器
service.interceptors.response.use(
  (response) => {
    if (response.data.code == "-2") {
      // 获取公共token
      getsystoken().then((res) => {
        if (res.code == "0") {
          localStorage.setItem("SysToken", "JWT " + res.token);
          message.error("网络请求失败请重新操作！");
        }
      });
    } else if (response.data.code == "-1") {
      message.error(response.data.message);
    }
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
